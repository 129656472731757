<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Almacen - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form>
              <b-row>
                <b-col md="2">
                  <b-form-group label="Código:">
                    <b-form-input disabled type="text" v-model="warehouse.code"></b-form-input>
                  </b-form-group>
                </b-col>
       
                <b-col md="6">
                  <b-form-group label="Nombre:">
                    <b-form-input disabled type="text" v-model="warehouse.name"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Nombre Corto:">
                    <b-form-input disabled type="text" v-model="warehouse.short_name"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="8">
                  <b-form-group label="Descripción:">
                    <b-form-input disabled type="text" v-model="warehouse.description"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="por defecto ?:">
                    <b-form-select disabled v-model="warehouse.default" :options="default_warehouse"></b-form-select>
                  </b-form-group>
                </b-col>
         
                <b-col md="2">
                  <b-form-group label="Estado :">
                    <b-form-select disabled v-model="warehouse.state" :options="state"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/almacen/listar' }" append>REGRESAR</b-link>
                </b-col>

              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

export default {
  name: "warehouseEdit",
  props: ["id_warehouse"],
  data() {
    return {
      module:'Warehouse',
      role:3,
      warehouse: {
        code: "",
        name: "",
        short_name: "",
        description: "",
        default: 1,
        state: 1,
      },
      default_warehouse:[
        {value: 1 , text : 'Si'},
        {value: 0 , text : 'No'},
      ],
      state:[
        {value: 1 , text : 'Activo'},
        {value: 0 , text : 'Inactivo'},
      ],
      //errors
      errors: {
        code: false,
        name: false,
        short_name: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewWarehouse();
  },
  methods: {
    ViewWarehouse,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};


//ver usuario
function ViewWarehouse() {
  let id_warehouse = je.decrypt(this.id_warehouse);
  let me = this;
  let url = this.url_base + "warehouse/view/" + id_warehouse;

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.warehouse.id_warehouse = response.data.result.id_warehouse;
        me.warehouse.code = response.data.result.code;
        me.warehouse.name = response.data.result.name;
        me.warehouse.short_name = response.data.result.short_name;
        me.warehouse.description = response.data.result.description;
        me.warehouse.default = response.data.result.default;
        me.warehouse.state = response.data.result.state;
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

</script>
